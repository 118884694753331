import Faucet from "./containers/Faucet";

const routes = [
  {
    path: "/faucet",
    element: <Faucet />,
  },
];

export default routes;
