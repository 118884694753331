import { Button, Input, message } from "antd";
import { Col, Row } from "../../components/common";
import ReCAPTCHA from "react-google-recaptcha";
import "./index.scss";
import React, { useEffect, useState } from 'react'
import { Select, Space } from 'antd';



const Faucet = () => {

  const [faucetObj, setFaucetObj] = useState({
    address: "",
    captcha: "",
    denom: "ucmdx"
  })
  const [loading, setLoading] = useState(false)
  const [selectedFaucet, setSelectedFaucet] = useState("testnet")
  const [selectedToken, setSelectedToken] = useState("ucmdx")

  function onCapchaChange(value) {
    setFaucetObj({
      ...faucetObj,
      captcha: value
    })
  }
  const onAddressChange = (value) => {
    setFaucetObj({
      ...faucetObj,
      address: value
    })
  }

  const handleDropdownChange = (value) => {
    setSelectedFaucet(value)
  };
  const handleTokenChange = (value) => {
    setSelectedToken(value)
  };

  const showAlert = (data) => {
    if (data?.error) {
      message.error(<b>{data?.error}</b>)
      window.grecaptcha.reset();
      setFaucetObj({
        address: "",
        captcha: "",
        denom: "ucmdx"
      })
      setLoading(false)
    }
    else {
      message.success(data?.data?.message)
      window.grecaptcha.reset();
      setFaucetObj({
        address: "",
        captcha: "",
        denom: "ucmdx"
      })
      setLoading(false)
    }
  }

  const handlesubmit = () => {
    // let url = selectedFaucet === "testnet" ? "https://testnet-stat.comdex.one/api/v2/faucet" : "https://stat.comdex.one/api/v2/faucet";
    let url;
    if (selectedFaucet === "testnet") {
      url = "https://testnet-stat.comdex.one/api/v2/faucet"
    }
    else if (selectedFaucet === "devnet") {
      url = "https://devnet-stat.comdex.one/api/v2/faucet"
    }
    else if (selectedFaucet === "mainnet") {
      url = "https://stat.comdex.one/api/v2/faucet"
    }
    else if (selectedFaucet === "novanet") {
      url = "https://nova-stat.comdex.one/api/v2/faucet"
    }
    else if (selectedFaucet === "nebula") {
      url = "https://nebula-stat.comdex.one/api/v2/faucet"
    }

    if (faucetObj?.address.startsWith("comdex")) {
      setLoading(true)
      fetch(url, {
        method: "POST",
        body: JSON.stringify(faucetObj),
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      })
        .then((response) => response.json())
        .then((json) => {
          showAlert(json)
          setLoading(false)
        });
    } else {
      window.grecaptcha.reset();
      setFaucetObj({
        address: "",
        captcha: "",
        denom: "ucmdx"
      })
      message.error(<b>Enter comdex address !</b>)

    }

  }

  useEffect(() => {
    const updatedFaucetObj = { ...faucetObj, denom: selectedToken };
    setFaucetObj(updatedFaucetObj);
  }, [selectedToken])

  return (
    <div className="faucet-card">
      <h1>Faucet For
        <Select
          style={{
            width: 120,
          }}
          value={selectedFaucet}
          onChange={handleDropdownChange}
          options={[
            {
              value: 'testnet',
              label: 'Testnet',
            },
            {
              value: 'mainnet',
              label: 'Mainnet',
            },
            {
              value: 'novanet',
              label: 'Novanet',
            },
            {
              value: 'devnet',
              label: 'Devnet',
            },
            {
              value: 'nebula',
              label: 'Nebula',
            },
          ]}
        />
        <Select
          style={{
            width: 120,
          }}
          value={selectedToken}
          onChange={handleTokenChange}
          options={[
            {
              value: 'ucmdx',
              label: 'CMDX',
            },
            {
              value: 'ucmst',
              label: 'CMST',
            },
          ]}
        />
      </h1>
      <p>Enter your comdex address to get the faucet tokens</p>
      <Row className='mt-4'>
        <Col>
          <Input placeholder="Enter your comdex address" value={faucetObj?.address} onChange={(e) => onAddressChange(e.target.value)} />
        </Col>
      </Row>
      <Row className='my-4'>
        <Col className='text-center'>
          <ReCAPTCHA
            style={{ display: "inline-block" }}
            sitekey="6LdfVZwkAAAAAMTKSuzm4PVMsnF6l_ynDPh0r7pD"
            onChange={onCapchaChange}

          />
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col>
          <Button
            type="primary btn-filled"
            size="large"
            disabled={
              !faucetObj.address ||
              !faucetObj.captcha ||
              loading
            }
            loading={loading}
            onClick={handlesubmit}
          >
            Send Tokens
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Faucet;
