import React from "react";
import svgFile from "./assets/images/svg/svg-sprite.svg";
import Router from "./Router";
import SvgSprite from "./utils/SvgSpriteLoader";

const App = () => {
  return (
    <>
      <SvgSprite url={svgFile} />
      <div className="main_wrapper">
        <div className="main-container">
          <Router />
        </div>
      </div>
    </>
  );
};

export default App;
